/**
 * Deployment to either of 'primary' or 'secondary' region.
 */
export type DeployRegion = 'primary' | 'secondary'

/**
 * Deployment to either 'master', 'release' or 'develop' environments.
 */
export type DeployStage = 'master' | 'release' | 'develop'

/**
 * Indicates if a failover is in progress.
 */
export type FailoverState = 'stable' | 'processing'

/**
 * Represents the currently active region configuration. Applications will be provided with credentials for both primary
 * and secondary regions; these clients should use the respective client credentials based on the 'activeRegion' field.
 * The 'authenticationUrl', 'apiUrl' and 'websocketUrl' fields will return the value of the currently active region.
 *
 * Additionally if clients are using the 'Authorization code grant' flow, separate refresh tokens needs to be maintained
 * for primary and secondary regions. See:
 * https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-app-idp-settings.html
 *
 * These values should NOT be used by API clients such as lambda's which are inherently regional and internally always
 * connects to other services in the same region. It is intended to be used by clients such as mobile applications and
 * external applications which always need to connect to the region currently active.
 */
export class ActiveRegion {
  readonly activeRegion: DeployRegion
  readonly failoverState: FailoverState
  readonly authenticationUrl: string
  readonly apiUrl: string
  readonly websocketUrl: string

  constructor (data: { [key: string]: string }) {
    this.activeRegion = data['active.region'] as DeployRegion
    this.failoverState = data['failover.state'] as FailoverState
    this.authenticationUrl = 'https://' + data[`${this.activeRegion}.authentication`]
    this.apiUrl = 'https://' + data[`${this.activeRegion}.api`]
    this.websocketUrl = 'wss://' + data[`${this.activeRegion}.websocket`]
  }
}

/**
 * Represents the current state ('active' field) of a region and applications may decide to turn off / on functionality
 * based on this state. The relevant URLs will be returned in 'authenticationUrl', 'apiUrl' and 'websocketUrl' and should
 * be used with the provided credentials for that region.
 *
 * These values should be used by API clients such as lambda's which are inherently regional and internally always
 * connects to other services in the same region. This could also be used by external clients for which it is acceptable to
 * be down during the time of the primary region being inactive.
 */
export class InternalRegion {
  readonly regionActive: boolean
  readonly failoverState: FailoverState
  readonly authenticationUrl: string
  readonly apiUrl: string
  readonly websocketUrl: string

  constructor (data: { [key: string]: string }, region: DeployRegion) {
    this.regionActive = region === data['active.region']
    this.failoverState = data['failover.state'] as FailoverState
    this.authenticationUrl = 'https://' + data[`${region}.authentication`]
    this.apiUrl = 'https://' + data[`${region}.api`]
    this.websocketUrl = 'wss://' + data[`${region}.websocket`]
  }
}

/**
 * Atlas config query by specifying a simple key.
 */
export interface DnsTxtConfigKeyQuery {
  readonly key: string
}

/**
 * Atlas config query by specifying a qualifier and type.
 */
export type DnsTxtConfigQualifierQuery = {
  readonly type: string
  readonly qualifier: string
}

/**
 * Atlas config response, key value pairs.
 */
export interface DnsTxtConfigResponse {
  [ key: string ]: string
}

/**
 * Class to load configuration from DNS server.
 */
export class AtlasConfig {
  static DEFAULT_DOMAIN_MASTER = 'atlas-config.mit.edu'
  static DEFAULT_DOMAIN_RELEASE = 'atlas-config-test.mit.edu'
  static DEFAULT_DOMAIN_DEVELOP = 'atlas-config-dev.mit.edu'

  static DEFAULTS_REGION_MASTER = {
    'active.region': 'primary',
    'failover.state': 'stable',
    'primary.authentication': 'atlas-auth.mit.edu',
    'primary.api': 'api.mit.edu',
    'primary.websocket': 'wss-api.mit.edu',
    'secondary.authentication': 'atlas-auth-us-west.mit.edu',
    'secondary.api': 'api-us-west.mit.edu',
    'secondary.websocket': 'wss-api-us-west.mit.edu'
  }

  static DEFAULTS_REGION_RELEASE = {
    'active.region': 'primary',
    'failover.state': 'stable',
    'primary.authentication': 'atlas-auth-test.mit.edu',
    'primary.api': 'api-test.mit.edu',
    'primary.websocket': 'wss-api-test.mit.edu',
    'secondary.authentication': 'atlas-auth-us-west-test.mit.edu',
    'secondary.api': 'api-us-west-test.mit.edu',
    'secondary.websocket': 'wss-api-us-west-test.mit.edu'
  }

  static DEFAULTS_REGION_DEVELOP = {
    'active.region': 'primary',
    'failover.state': 'stable',
    'primary.authentication': 'atlas-auth-dev.mit.edu',
    'primary.api': 'api-dev.mit.edu',
    'primary.websocket': 'wss-api-dev.mit.edu',
    'secondary.authentication': 'atlas-auth-us-west-dev.mit.edu',
    'secondary.api': 'api-us-west-dev.mit.edu',
    'secondary.websocket': 'wss-api-us-west-dev.mit.edu'
  }

  readonly stage: DeployStage
  readonly domain?: string

  static forStage (stage: DeployStage, domain: string | undefined = undefined) {
    return new AtlasConfig(stage, domain)
  }

  private constructor (stage: DeployStage, domain?: string) {
    this.stage = stage
    this.domain = domain
  }

  /**
   * Get atlas config from DNS host.
   * @param q
   */
  async get (q: DnsTxtConfigKeyQuery | DnsTxtConfigQualifierQuery): Promise<DnsTxtConfigResponse> {
    const query: any = q
    let key = ''
    if (query.qualifier && query.type) {
      key = `${query.qualifier}-${query.type}`
    } else {
      key = query.key
    }

    let configDomain = this.domain
    if (configDomain === undefined) {
      // default domain for stage
      if (this.stage === 'master') {
        configDomain = AtlasConfig.DEFAULT_DOMAIN_MASTER
      } else if (this.stage === 'release') {
        configDomain = AtlasConfig.DEFAULT_DOMAIN_RELEASE
      } else if (this.stage === 'develop') {
        configDomain = AtlasConfig.DEFAULT_DOMAIN_DEVELOP
      }
    } else {
      // use provided domain
    }
    const fqn = `${key}.${configDomain}`
    console.log(`Config URL: ${fqn}`)

    // @ts-ignore TS2304: Cannot find name 'window'
    if (typeof window !== 'undefined') { // eslint-disable-line no-undef
      // Execute http get in a browser, DNS lookup is not possible
      const url = `get.${configDomain}?key=${key}`
      // @ts-ignore TS2304: Cannot find name 'fetch'
      const response = await fetch(url) // eslint-disable-line no-undef
      return await response.json()
    } else {
      // Use the faster and more reliable DNS method to lookup values
      const dns = require('dns')
      const resolver = new dns.promises.Resolver()
      const addresses = await resolver.resolveTxt(fqn)

      const response = Object.assign({}, ...addresses.map((item: string[]) => {
        const res: { [key: string] : string } = {}
        const parts = item[0].split('=')
        res[parts[0]] = parts[1]
        return res
      }))
      return response
    }
  }

  /**
   * Get the currently active region, see ActiveRegionConfig for full details.
   */
  async getActiveRegion (): Promise<ActiveRegion> {
    // DNS Query
    let result: ActiveRegion | undefined
    try {
      const response = await this.get({ qualifier: 'active', type: 'region' })
      if (response && response['active.region'] != null && response['failover.state'] != null &&
        response['primary.authentication'] != null && response['primary.api'] != null && response['primary.websocket'] != null &&
        response['secondary.authentication'] != null && response['secondary.api'] != null && response['secondary.websocket'] != null
      ) {
        result = new ActiveRegion(response)
      } else {
        throw new Error('All the required values were not returned')
      }
    } catch (error) {
      console.error(error)
    }

    // Falls back to defaults if failed
    if (result === undefined) {
      if (this.stage === 'master') {
        result = new ActiveRegion(AtlasConfig.DEFAULTS_REGION_MASTER)
      } else if (this.stage === 'release') {
        result = new ActiveRegion(AtlasConfig.DEFAULTS_REGION_RELEASE)
      } else if (this.stage === 'develop') {
        result = new ActiveRegion(AtlasConfig.DEFAULTS_REGION_DEVELOP)
      }
    }

    return result!
  }

  /**
   * Get the internal region config, see InternalRegionConfig for full details.
   * @param region
   */
  async getInternalRegion (region: DeployRegion): Promise<InternalRegion> {
    // DNS Query
    let result: InternalRegion | undefined
    try {
      const response = await this.get({ qualifier: 'active', type: 'region' })
      if (response && response['active.region'] != null && response['failover.state'] != null &&
        response['primary.authentication'] != null && response['primary.api'] != null && response['primary.websocket'] != null &&
        response['secondary.authentication'] != null && response['secondary.api'] != null && response['secondary.websocket'] != null
      ) {
        result = new InternalRegion(response, region)
      } else {
        throw new Error('All the required values were not returned')
      }
    } catch (error) {
      console.error(error)
    }

    // Falls back to defaults if failed
    if (result === undefined) {
      if (this.stage === 'master') {
        result = new InternalRegion(AtlasConfig.DEFAULTS_REGION_MASTER, region)
      } else if (this.stage === 'release') {
        result = new InternalRegion(AtlasConfig.DEFAULTS_REGION_RELEASE, region)
      } else if (this.stage === 'develop') {
        result = new InternalRegion(AtlasConfig.DEFAULTS_REGION_DEVELOP, region)
      }
    }

    return result!
  }
}
